<!--
 * @Description: 上传
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-27 16:49:03
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/clue/mods/upload.vue
-->
<template>
  <div class="model-popup" @click="shows = false">
    <div class="model" @click.stop>
      <div class="model-text">批量上传线索</div>
      <div class="model-sub-text">客户线索转化为购买套餐客户</div>
      <div class="model-content">
        <div class="content-list">
          <div class="content-item" @click="handleDownload">
            <div class="item-icon">
              <img src="../../../../../assets/icon/add2.png" alt="" />
            </div>
            <div class="item-text">下载线索模版Excel</div>
          </div>
          <div class="content-item content-upload" @click="handleUpload">
            <div class="item-icon">
              <img src="../../../../../assets/icon/add1.png" alt="" />
            </div>
            <div class="item-text">上传批量线索Excel</div>
          </div>
        </div>
      </div>
      <div class="model-file">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-remove="handleRemove"
          :file-list="fileList"
          :auto-upload="false"
        >
        </el-upload>
      </div>
      <div class="model-btn">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="model-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getToken, uploadImage } from "../../../../../utils/common";
import httpConfig from "../../../../../utils/index";

const URL = {
  upload: "api/operation/customerLeads/upload",
};

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      fileList: [],
      files: null,
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    handleRemove() {
      this.files = null;
    },
    // 下载
    handleDownload() {
      window.open(
        "https://aboostify.oss-cn-hangzhou.aliyuncs.com/workstation/system/template/客户线索模板.xlsx"
      );
    },
    // 选择文件
    async handleUpload() {
      const files = await uploadImage(["xlsx", "xls"]);
      this.fileList = [
        {
          name: files.name,
        },
      ];
      this.files = files;
    },
    async handleConfirm() {
      if (!this.files) {
        this.$message.error("请选择需要上传的文件");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.files);
      const url = `${httpConfig.URL_PREFIX_API[1]}${URL.upload}`;
      const resp = await fetch(url, {
        method: "post",
        headers: {
          token: getToken(),
        },
        body: formData,
      });
      const res = await resp.json();
      if (res.code === 200) {
        this.shows = false;
        this.$message.success("上传成功");
        this.$emit("update");
      } else {
        this.$message.error(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.model-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .model {
    width: 510px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-sub-text {
      font-size: 20px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #b4b4b4;
      line-height: 24px;
      text-align: center;
      margin-top: 14px;
    }
    &-content {
      margin-top: 37px;
      .content-list {
        display: flex;
        justify-content: center;
        .content-item {
          width: 177px;
          height: 179px;
          background: rgba(238, 128, 49, 0.08);
          border-radius: 6px 6px 6px 6px;
          margin-right: 30px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .item-icon {
            margin: auto {
              top: 42px;
            }
            width: 60px;
            height: 60px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
            }
          }
          .item-text {
            text-align: center;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
            font-weight: normal;
            color: #7c7f97;
            line-height: 24px;
            margin-top: 26px;
          }
        }
        .content-upload {
          background: #eff2ff;
        }
      }
    }
    &-file {
      width: calc(177px * 2 + 30px);
      position: absolute;
      left: 63px;
      top: calc(179px + 149px);
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 103px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
