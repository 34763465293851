<!--
 * @Description: 客户线索
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 20:12:30
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/clue/index.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">客户线索</div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="68px"
      style="margin-top: 20px"
    >
      <el-form-item label="时间:" prop="createTime">
        <el-date-picker
          align="center"
          style="width: 240px"
          v-model="queryParams.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公司名称" prop="search">
        <el-input
          style="width: 150px"
          v-model="queryParams.search"
          placeholder="公司名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态" prop="contact_state">
        <el-select
          style="width: 150px"
          v-model="queryParams.contact_state"
          placeholder="请选择"
        >
          <el-option label="未联系" :value="1"></el-option>
          <el-option label="已联系" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="getList()"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
        <el-dropdown @command="handleOpts">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="mini"
            style="margin-left: 10px"
            >录入线索 <i class="el-icon-caret-bottom"></i
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="add">手动录入</el-dropdown-item>
            <el-dropdown-item command="upload">上传Excel</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="616"
      style="width: 100%"
      v-loading="isLoading"
    >
      <el-table-column
        prop="created_at"
        show-overflow-tooltip
        label="时间"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        show-overflow-tooltip
        label="姓名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        show-overflow-tooltip
        label="联系方式"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        show-overflow-tooltip
        label="公司"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="company_nick_name"
        show-overflow-tooltip
        label="公司简称"
        align="center"
      >      
      </el-table-column>
      <el-table-column
        prop="consult"
        show-overflow-tooltip
        label="咨询"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="{ row }">
          <el-dropdown
            v-if="`${row.contact_state}` === '1'"
            @command="handleOpts($event, row)"
          >
            <div :class="['state-btn', 'warning', 'state-warning']">
              未联系
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="state">已联系</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div
            v-else-if="`${row.contact_state}` === '2'"
            :class="['state-btn']"
          >
            已联系
            <i class="el-icon-caret-bottom"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="opt"
        show-overflow-tooltip
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            v-if="`${row.transformed_state}` === '1'"
            class="state-btn state-primary warning"
            @click="handleOpts('transform', row)"
          >
            客户转化
          </div>
          <div v-else class="state-btn">客户转化</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastRemarks"
        show-overflow-tooltip
        label="备注"
        align="center"
      >
        <template slot-scope="{ row }">
          <span class="warning" @click="handleOpts('remarks', row)">{{
            row.lastRemarks || "-"
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />

    <!-- 录入 -->
    <AddView v-if="add.show" :show.sync="add.show" @update="getList(1)" />

    <!-- 上传 -->
    <UploadView
      v-if="upload.show"
      :show.sync="upload.show"
      @update="getList(1)"
    />

    <RemarksView
      v-if="remarks.show"
      :show.sync="remarks.show"
      v-bind="remarks"
      @update="getList(1)"
    />

    <TransformView
      v-if="transform.show"
      :show.sync="transform.show"
      v-bind="transform"
      @update="getList(1)"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../utils/request";
import AddView from "./mods/add.vue";
import UploadView from "./mods/upload.vue";
import RemarksView from "./mods/remarks.vue";
import TransformView from "./mods/transform.vue";

const URL = {
    list: "api/operation/customerLeads/list",
    mark: "api/operation/customerLeads/mark",
  },
  QUERY = {
    createTime: [],
    search: "",
    contact_state: null,
  };
export default {
  components: {
    AddView,
    UploadView,
    RemarksView,
    TransformView,
  },
  data() {
    return {
      queryParams: {
        ...QUERY,
      },
      tableData: [],
      add: {
        show: false,
      },
      remarks: {
        show: false,
        data: null,
      },
      transform: {
        show: false,
        data: null,
      },
      upload: {
        show: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        ...QUERY,
      };
      this.getList(1);
    },
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const [beginTime = null, endTime = null] =
        this.queryParams.createTime || [];

      const params = {
        beginTime,
        endTime,
        search: this.queryParams.search,
        contact_state: this.queryParams.contact_state,
      };
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          ...params,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    async handleMark({ id }) {
      await HTTP({
        url: URL.mark,
        method: "post",
        data: {
          id,
          contact_state: 2,
        },
      });
      this.$message.success("操作成功");
      this.getList();
    },
    handleOpts(type, row) {
      switch (type) {
        case "state":
          this.handleMark(row);
          break;
        case "add":
          this.add = {
            show: true,
          };
          break;
        case "upload":
          this.upload = {
            show: true,
          };
          break;
        case "remarks":
          this.remarks = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        case "transform":
          this.transform = {
            show: true,
            data: {
              ...row,
            },
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning {
  cursor: pointer;
}
</style>
